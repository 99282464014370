import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormGroup,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  TextField,
  Autocomplete,
  Box,
  Typography,
  Button,
} from "@mui/material";
import React from "react";

function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

const HtmlLabel = ({ htmlContent, ...props }) => {
  let styledHtmlContent = htmlContent;

  // Regex to identify "/p" followed by optional whitespace, colon, or dash, and then everything after it
  const slashPRegex = /\/p\s*[:\-]?\s*(.*)/i;

  // Apply description regex styling
  const match = slashPRegex.exec(styledHtmlContent);
  if (match) {
    const prefix = styledHtmlContent.substring(0, match.index);
    const suffix = match[1]; // Everything after the matched "/p..." pattern

    styledHtmlContent = `
      <span style="color: #05386B;">${prefix}</span>
      <div style="color: gray; font-size: 0.9em;">${suffix}</div>
    `;
  } else {
    styledHtmlContent = `
      <span style="color: #05386B;">${styledHtmlContent}</span>
    `;
  }
  // Replace new line characters with <br />
  styledHtmlContent = styledHtmlContent.replace(/\/n/g, '<br />');

  // Apply /p regex removal from the final styled content
  styledHtmlContent = styledHtmlContent.replace(/<span style="color: gray;">\/p\s*[:\-]?\s*/i, '<span style="color: gray;">');

  return (
    <FormLabel {...props}>
      <span dangerouslySetInnerHTML={{ __html: styledHtmlContent }} />
    </FormLabel>
  );
};

const PreviewRadio = ({ data }) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = data.label;

  const plainText = tempElement.textContent;

  const trimmedLabel = plainText.replace(/&nbsp;/g, "");
  const selected = data.values.filter((itm) => itm.selected === true);
  return (
    <FormControl>
      <HtmlLabel id="demo-radio-buttons-group-label" htmlContent={trimmedLabel} />
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={selected.length > 0 ? selected[0].value : ""}
        name={data.name}
        row
      >
        {data.values.map((option) => (
          <FormControlLabel
            value={option.value}
            control={
              <Radio
              color={
                (option.value.toLowerCase() == "pass" || option.value.toLowerCase() == "yes" || option.value.toLowerCase() == "accept")
                  ? "success"
                  : (option.value.toLowerCase() == "fail" || option.value.toLowerCase() == "no" || option.value.toLowerCase() == "reject")
                  ? "error"
                  : "primary"
              }
                name={option.name}
                value={option.value}
              />
            }
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const PreviewCheckbox = ({ data }) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = data.label;

  const plainText = tempElement.textContent;

  const trimmedLabel = plainText.replace(/&nbsp;/g, "");
  return (
    <FormControl>
      <HtmlLabel id="demo-radio-buttons-group-label" htmlContent={trimmedLabel} />
      <FormGroup>
        {data.values.map((option) => (
          <FormControlLabel
            control={
              <Checkbox
                name={option.name}
                value={option.value}
                defaultChecked={option.selected}
              />
            }
            name={data.name}
            label={option.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const PreviewSelect = ({ data }) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = data.label;

  const plainText = tempElement.textContent;

  const trimmedLabel = plainText.replace(/&nbsp;/g, "");
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{trimmedLabel}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        fullWidth
        label={trimmedLabel}
        name={data.name}
      >
        {data.values.map((option) => (
          <MenuItem value={option.value} selected={option.selected}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const PreviewTextField = ({ data }) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = data.label;

  const plainText = tempElement.textContent;

  const trimmedLabel = plainText.replace(/&nbsp;/g, "");
  return (
    <FormControl fullWidth>
      <TextField
        id={trimmedLabel}
        label={trimmedLabel}
        variant="outlined"
        fullWidth
        name={data.name}
      />
      <FormHelperText>{data?.description}</FormHelperText>
    </FormControl>
  );
};

const PreviewTextAreaField = ({ data }) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = data.label;

  const plainText = tempElement.textContent;

  const trimmedLabel = plainText.replace(/&nbsp;/g, "");
  return (
    <FormControl fullWidth>
      <TextField
        id={trimmedLabel}
        label={trimmedLabel}
        variant="outlined"
        multiline
        fullWidth
        name={data.name}
        minRows={3}
      />
      <FormHelperText>{data?.description}</FormHelperText>
    </FormControl>
  );
};

const PreviewNumberField = ({ data }) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = data.label;

  const plainText = tempElement.textContent;

  const trimmedLabel = plainText.replace(/&nbsp;/g, "");
  return (
    <FormControl>
      <TextField
        type={"number"}
        id={trimmedLabel}
        label={trimmedLabel}
        name={data.name}
        variant="outlined"
        fullWidth
      />
      <FormHelperText>{data?.description}</FormHelperText>
    </FormControl>
  );
};

const PreviewAutoCompleteField = ({ data }) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = data.label;

  const plainText = tempElement.textContent;

  const trimmedLabel = plainText.replace(/&nbsp;/g, "");
  return (
    <FormControl fullWidth>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={data.values}
        freeSolo={!data.requireValidOption}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={data.name}
            type="text"
            label={trimmedLabel}
          />
        )}
      />
    </FormControl>
  );
};

const PreviewDateField = ({ data }) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = data.label;

  const plainText = tempElement.textContent;

  const trimmedLabel = plainText.replace(/&nbsp;/g, "");
  return (
    <FormControl sx={{ py: 2 }}>
      <TextField
        sx={{ height: "40px" }}
        shrink
        type={"date"}
        fullWidth
        name={data.name}
        id={trimmedLabel}
        label={trimmedLabel}
        focused
      />
      <FormHelperText>{data?.description}</FormHelperText>
    </FormControl>
  );
};

const PreviewTypography = ({ data }) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = data.label;

  const plainText = tempElement.textContent;

  const trimmedLabel = plainText.replace(/&nbsp;/g, "");
  return (
    <Box my={1} className="preview-typo">
      <Typography variant={data.subtype} component={data.subtype}>
        {trimmedLabel}
      </Typography>
    </Box>
  );
};

const PreviewUploadField = ({ data }) => {
  return (
    <FormControl>
      <Button variant="outlined">Upload</Button>
    </FormControl>
  );
};

const PreviewImage = ({ data }) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = data.label;

  const plainText = tempElement.textContent;

  const trimmedLabel = plainText.replace(/&nbsp;/g, "");
  if (data.value && data.value?.success) {
    return (
      <FormControl>
        <HtmlLabel id="demo-radio-buttons-group-label" htmlContent={trimmedLabel} />
        <img
          src={data.value?.file_path}
          class="preview-img"
          alt="preview-details"
        />
      </FormControl>
    );
  }
  return (
    data.value && (
      <FormControl>
        <HtmlLabel id="demo-radio-buttons-group-label" htmlContent={trimmedLabel} />
        <Box sx={{ display: "flex", gap: "5px" }}>
          {String(data.value)
            .split(",")
            .map((prev) => {
              if (typeof prev === "object") {
                return (
                  <Box>
                    <img
                      src={URL.createObjectURL(prev)}
                      class="preview-img"
                      alt="preview-details"
                    />
                  </Box>
                );
              }
              return (
                <Box>
                  <img
                    src={
                      isValidUrl(prev)
                        ? prev
                        : process.env.REACT_APP_API_BASE + "/" + prev
                    }
                    class="preview-img"
                    alt="preview-details"
                  />
                </Box>
              );
            })}
        </Box>
      </FormControl>
    )
  );
};

export {
  PreviewRadio,
  PreviewCheckbox,
  PreviewSelect,
  PreviewTextField,
  PreviewTextAreaField,
  PreviewNumberField,
  PreviewAutoCompleteField,
  PreviewDateField,
  PreviewTypography,
  PreviewUploadField,
  PreviewImage,
};
